//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { loadMoreList, tool, commonData } from "@/mixins/index";
export default {
  name: "gatewayRealName",
  mixins: [loadMoreList, tool, commonData],
  data: function data() {
    return {
      pagingReqFn: "userRealNameList",
      dateType: 'arr'
    };
  },
  computed: {},
  created: function created() {// this.getNowDate(this.dateType);
  },
  activated: function activated() {
    this.getcommonData(['appListAllData']);
  },
  methods: {
    getListNew: function getListNew() {
      if (!this.paramsData.user_id && !this.paramsData.gate_user_id && !this.paramsData.idcard) {
        this.$message.error('查询时用户ID、网关用户ID、身份信息最少需要传一个');
        return;
      }

      if (this.paramsData.gate_user_id && !this.paramsData.appid) {
        this.$message.error('查询时网关用户ID时请选择应用');
        return;
      }

      this.getList();
    }
  }
};