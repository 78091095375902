var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      staticStyle: { "pointer-events": "auto" },
      attrs: {
        title: _vm.formData.id ? "修改" : "新增",
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        "modal-append-to-body": true,
        modal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: function($event) {
          return _vm.rewriteClearData()
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "应用", prop: "appid" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.formData.id ? true : false,
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择"
                  },
                  model: {
                    value: _vm.formData.appid,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "appid", $$v)
                    },
                    expression: "formData.appid"
                  }
                },
                _vm._l(_vm.appListAllData, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.appid }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "登录类型", prop: "login_type_id" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择"
                  },
                  on: { change: _vm.payTypeChange },
                  model: {
                    value: _vm.formData.login_type_id,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "login_type_id", $$v)
                    },
                    expression: "formData.login_type_id"
                  }
                },
                _vm._l(_vm.appLoginTypeRes, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.type_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._l(_vm.formData.params, function(domain, index) {
            return _c("div", { key: index + "k", staticClass: "paramsClass" }, [
              _c(
                "div",
                { staticClass: "lodingItem-operationFrame flex" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "lodingItem",
                      staticStyle: { "margin-right": "20px" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: true,
                          clearable: "",
                          placeholder: "请输入"
                        },
                        model: {
                          value: domain.label,
                          callback: function($$v) {
                            _vm.$set(domain, "label", $$v)
                          },
                          expression: "domain.label"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "lodingItem",
                      attrs: { prop: "params." + index + ".value" }
                    },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: domain.value,
                          callback: function($$v) {
                            _vm.$set(domain, "value", $$v)
                          },
                          expression: "domain.value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          })
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { round: "" },
              on: {
                click: function($event) {
                  _vm.dialogFormVisible = false
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "warning", round: "" },
              on: {
                click: function($event) {
                  return _vm.rewriteClearData(false)
                }
              }
            },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: {
                click: function($event) {
                  return _vm.dataFormSubmit("ruleForm")
                }
              }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }