//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { loadMoreList, tool, commonData } from "@/mixins/index";
import phoneEdit from "./components/phoneEdit";
export default {
  name: "phoneLogin",
  mixins: [loadMoreList, tool, commonData],
  components: {
    phoneEdit: phoneEdit
  },
  data: function data() {
    return {
      paramsData: {
        isMustAppid: 1
      },
      pagingReqFn: "phoneList"
    };
  },
  created: function created() {},
  activated: function activated() {
    this.getcommonData(['appListAllData']);
  },
  methods: {}
};