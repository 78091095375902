export default {
  data: function data() {
    return {
      searchDateStr: '',
      // 时间选择单选
      searchDateArr: [],
      // 时间选择范围
      dateType: 'str',
      // 时间选择类型
      dateTypeKey: 'time',
      paramsData: {
        appid: '',
        user_id: '',
        gate_user_id: ''
      },
      DefaultData: {
        // 搜索默认固定参数
        page: 1,
        page_size: 10
      }
    };
  },
  activated: function activated() {},
  mounted: function mounted() {},
  methods: {
    // 获取当前筛选时间
    getNowDate: function getNowDate() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'str';
      var timestamp = Date.parse(new Date());

      if (type == 'str') {
        this.searchDateStr = timestamp;
        this.paramsData[this.dateTypeKey] = timestamp / 1000;
      } else if (type == 'arr') {
        this.searchDateArr = [timestamp, timestamp];
        this.paramsData.start_time = timestamp / 1000;
        this.paramsData.end_time = timestamp / 1000;
      }
    },
    // 时间选择处理函数
    seachDateChange: function seachDateChange(e) {
      console.log(e);

      if (this.dateType == 'str') {
        this.paramsData[this.dateTypeKey] = e / 1000;
      } else if (this.dateType == 'arr') {
        this.paramsData.start_time = e ? e[0] / 1000 : '';
        this.paramsData.end_time = e ? e[1] / 1000 : '';
      }

      console.log(this.paramsData);
    },
    // 重置操作
    resetSearch: function resetSearch() {
      for (var k in this.paramsData) {
        if (k == 'page' || k == 'page_size') {
          this.paramsData[k] = this.DefaultData[k];
        } else {
          this.paramsData[k] = '';
        }
      }

      this.getNowDate(this.dateType);
    }
  }
};